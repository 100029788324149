/* eslint-disable max-len */
import { RemoteErrorType, RemoteScope } from "../../store/RemoteTypes";
import { RemoteErrors } from "../../store/ReduxState";
import { Map } from "immutable";

export const getErrorValidationMessage =
    (error: Map<RemoteScope, RemoteErrors>, rc: RemoteScope): RemoteErrors | undefined => {
        const errorEntry = error.findEntry((val) => !!val && val.type === RemoteErrorType.VALIDATION);
        let errMessage;
        if (errorEntry) {
            const [scope, reason] = errorEntry;
            errMessage = scope === rc ? reason : undefined;
        }
        return errMessage;
    };

export const hasErrorValidation =
    (error: Map<RemoteScope, RemoteErrors>, rc: RemoteScope): boolean => {
        const errorEntry = error.findEntry((val) => !!val && val.type === RemoteErrorType.VALIDATION);
        let hasError = false;
        if (errorEntry) {
            const [scope] = errorEntry;
            hasError = scope === rc;
        }
        return hasError;
    };


export type ErrorData = Array<{ errorCode: number; message: string }> | { message: string };

export const getRawValidationMessages = (data: ErrorData) => {
    let messages = "";
    if (Array.isArray(data)) {
        data.forEach(entry => {
            messages += `${entry.message}\n`;
        });
    } else {
        messages = data.message;
    }
    return messages;
};
