import * as React from "react";
import { asThemeConsumer } from "../../../theme/Theme";
import { ThemeConsumerProps } from "../../../theme/_Types";
import { joinClasses } from "../../../utils/Filters";

export enum LinkTarget {
    SELF = "_self",
    BLANK = "_blank",
}

export type LinkProps = LinkPropsOnClick | LinkPropsHref;

export interface LinkPropsOnClick {
    onClick: (evt: React.MouseEvent | React.TouchEvent) => void;
}

export interface LinkPropsHref {
    href: string;
    target?: LinkTarget;
    download?: boolean;
}

export const isLinkPropsHref = (p?: LinkProps): p is LinkPropsHref => !!p && p.hasOwnProperty("href");
export const isLinkPropsOnClick = (p?: LinkProps): p is LinkPropsOnClick => !!p && p.hasOwnProperty("onClick");

/**
 *
 */
type Props = ThemeConsumerProps & {
    link?: LinkProps;
    disabled?: boolean;
};

/**
 *
 * @param props
 */
const LinkComp: React.FunctionComponent<Props> = props => {
    const clazzName = joinClasses(
        "scl-a-link",
        props.className,
    );

    return (
        isLinkPropsHref(props.link) && !props.disabled ?
            (
                <a style={props.style} className={clazzName} href={props.link.href} download={props.link.download}
                    target={props.link.target}>
                    {props.children}
                </a>
            )
            : (
                <span style={props.style} className={clazzName} onClick={!isLinkPropsHref(props.link) && !props.disabled
                    ? props?.link?.onClick : undefined}>
                    {props.children}
                </span>
            )
    );
};

/**
 *
 */
export const Link = asThemeConsumer(LinkComp);
