/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { connect } from "react-redux";
import { DispatchFunc } from "../../../store/ActionTypes";
import { appInit, signout } from "../../../store/AppActions";
import { UserInfo } from "../../../store/AppTypes";
import { Container } from "../../../components/core/03-base/Container";
import { FlexColumn } from "../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../components/core/03-base/Flex/Row";
import { Div } from "../../../components/core/03-base/Div";
import { Grid } from "../../../components/core/03-base/Grid";
import { ThemeBreakpoints, ThemePalette, ThemeShadowSizes } from "../../../theme/_Types";
import { Navbar } from "../../../components/core/07-organisms/Navbar";
import { NavbarStack, NavbarStackContent } from "../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../components/core/07-organisms/Navbar/Title";
import { RTE } from "../../../components/core/07-organisms/RTE";
import { Alignment, ButtonsWrapper, Orientation } from "../../../components/core/06-molecules/ButtonsWrapper";
import { LogoutBtn } from "./keycloaklogoutbtn";
import { ReduxState } from "../../../store/ReduxState";

interface OwnProps {
    onAuthenticating?: React.ReactNode;
}

interface DispatchProps {
    triggerInit: (userInfo?: UserInfo) => void;
    signout: () => void;
}

interface StateProps {
    token?: string;
}

type Props = React.PropsWithChildren<OwnProps & DispatchProps & StateProps>;

export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    triggerInit: (userInfo?: UserInfo) => dispatch(appInit(userInfo)),
    signout: () => dispatch(signout()),
});

const mapStateToProps = (s: ReduxState): StateProps => ({
    token: s.prop("user")?.isPresent() ? s.prop("user").get()?.token : undefined,
});


export const KeycloakAuthenticatedComp: React.FC<Props> = (props) => {
    const [hasError, setError] = useState(false);
    const { keycloak } = useKeycloak();
    useEffect(() => {
        if (keycloak?.token) {
            setError(false);
            props.triggerInit({
                token: keycloak?.token,
                userName: (keycloak?.idTokenParsed as {name?: string})?.name  ?? "",
            });
        }

        if (keycloak) {
            keycloak.onAuthError = () => {
                setError(true);
            };
        }
        if (!keycloak?.authenticated) {
            console.warn("Claude detects that you are not authenticated.");
            if (props.token) {
                props.signout();
            }
        } else {
            console.log("Claude has verified that you are authenticated with IRIS.");
        }


        const handleFocus = () => {
            try {
                if (!localStorage.getItem?.("token")) {
                    keycloak?.logout({redirectUri: process.env.REACT_APP_URL}).then(() => {
                        console.log("Iris: Logout success");
                    }).catch(() => {
                        console.warn("Iris: Logout failed");
                    });
                    localStorage.clear();
                }
            } catch (e) {
                console.warn("Claude can't handle focus of window:", e);
            }
        };

        window.addEventListener("focus", handleFocus);

        return () => {
            window.removeEventListener("focus", handleFocus);
        };
    });

    return (
        <>
            {hasError ? (
                <FlexColumn isPage theme={{palette: ThemePalette.CONTRAST_SECONDARY}} >
                    <FlexRow isFixed>
                        <Container
                            theme={{
                                padding: { "": { y: 3 }, "sm": { y: 4 } },
                            }}
                        >
                            <Div className="scl-b-row">
                                <Grid
                                    offset={{ xs: 0, md: 3 }}
                                    size={{ xs: 12, md: 6 }}
                                    theme={{
                                        padding: {
                                            "": { b: 3, t: 1 },
                                            "md": { b: 4, t: 2 },
                                        },
                                    }}
                                >
                                    <Div theme={{shadow: ThemeShadowSizes.TINY}}>
                                        <FlexColumn>
                                            <FlexRow isFixed>
                                                <Navbar
                                                    theme={{
                                                        palette: ThemePalette.CONTRAST_TERTIARY,
                                                    }}
                                                >
                                                    <NavbarStack>
                                                        <NavbarStackContent>
                                                            <NavbarTitle>
                                                                Authentication failed
                                                            </NavbarTitle>
                                                        </NavbarStackContent>
                                                    </NavbarStack>
                                                </Navbar>
                                            </FlexRow>
                                            <FlexRow
                                                theme={{
                                                    palette: ThemePalette.CONTRAST_PRIMARY,
                                                }}
                                            >
                                                <Container
                                                    theme={{
                                                        padding: {
                                                            "": { b: 3, t: 3 },
                                                            "md": { b: 4, t: 4 },
                                                        },
                                                    }}
                                                >
                                                    <RTE>Something went wrong, we can&apos;t seem to authenticate you.</RTE>
                                                </Container>
                                            </FlexRow>
                                            <FlexRow isFixed>
                                                <FlexColumn
                                                    theme={{
                                                        palette: ThemePalette.CONTRAST_TERTIARY,
                                                    }}
                                                >
                                                    <Container
                                                        theme={{
                                                            padding: { "": { y: 2 } },
                                                        }}
                                                    >
                                                        <ButtonsWrapper
                                                            orientations={{ [ThemeBreakpoints.XS]: Orientation.HORIZONTAL}}
                                                            alignment={Alignment.CENTER}
                                                        >
                                                            <LogoutBtn noIcon />
                                                        </ButtonsWrapper>
                                                    </Container>
                                                </FlexColumn>
                                            </FlexRow>
                                        </FlexColumn>
                                    </Div>
                                </Grid>
                            </Div>
                        </Container>
                    </FlexRow>
                </FlexColumn>) :
                keycloak?.authenticated && props.children ? (
                    React.cloneElement(props.children as React.FunctionComponentElement<{authenticated?: boolean}>, { authenticated: keycloak?.authenticated })
                ) : (
                    props.onAuthenticating ? props.onAuthenticating : null
                )
            }
        </>
    );
};

/**
 *
 */
export const KeycloakAuthenticated = connect(
    mapStateToProps,
    mapDispatchToProps,
)(KeycloakAuthenticatedComp);
