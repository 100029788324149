/* eslint-disable */
// import { Auth } from "aws-amplify";
import { Map } from "immutable";
import React from "react";
import { connect } from "react-redux";

import { DispatchFunc } from "../src/store/ActionTypes";
import { isRemoteLoading, ReduxState, RemoteErrors } from "../src/store/ReduxState";
import { Blockquote } from "./components/core/05-atoms/Blockquote";
import { RTE } from "./components/core/07-organisms/RTE";
import { ModalNotification } from "./components/core/09-views/03-modals/Notification";
import { remoteClearError } from "./store/RemoteActions";
import { RemoteErrorType, RemoteScope } from "./store/RemoteTypes";
import { ThemePalette, ThemePaletteState} from "./theme/_Types";
import { modalClose, modalShow } from "./utils/redux/ActionTypes";
import * as I18n from "./utils/translations/I18n";
import { Component } from "./App/Component";

/**
 *
 */
interface OwnProps {
}

interface State {
    init: boolean;
}

/**
 *
 */
interface DispatchProps {
    handleRemoteError: (scope: RemoteScope, error: RemoteErrors) => void;
}

/**
 *
 */
interface StateProps {
    remoteErrors: Map<RemoteScope, RemoteErrors>;
    isAuthenticated: boolean;
    isLoading: boolean;
}


interface State {
}

const handleRemoteErrorModal = (id: string, onClose: () => void, error: RemoteErrors) => {
    const modal = (
        <ModalNotification
            refreshButton={error.type === RemoteErrorType.AUTHORIZATION}
            id={id}
            title={error.type === RemoteErrorType.VALIDATION
                ? I18n.getText("app.notification", "Notification")
                : I18n.getText("app.warning", "Warning")}
            onCloseModal={onClose}
            theme={{
                paletteState: error.type !== RemoteErrorType.VALIDATION
                    ? error.type === RemoteErrorType.UNKNOWN_ERROR
                        || error.type === RemoteErrorType.DEFAULT
                        ? ThemePaletteState.DANGER : ThemePaletteState.WARNING
                    : undefined,
                palette: error.type === RemoteErrorType.VALIDATION ? ThemePalette.CONTRAST_TERTIARY
                    : undefined,
            }}
        >
            <React.Fragment>
                <Blockquote
                    style={{
                        maxHeight: "200px",
                        overflowY: "scroll",
                        wordWrap: "break-word",
                        whiteSpace: "pre-line"
                    }}
                >
                    <RTE>
                        <h5>
                            {error.type !== RemoteErrorType.UNKNOWN_ERROR ?
                                I18n.getText("app.remote-error", "Claude can't continue with your request.") :
                                I18n.getText("app.fatal-remote-error", "Even Claude doesn't know what went wrong here!")
                            }
                        </h5>
                        {error.message}
                    </RTE>
                </Blockquote>
            </React.Fragment>
        </ModalNotification>
    );
    return () => modal;
};

const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    handleRemoteError: (scope, error) => dispatch(
        modalShow("handleremoteerror", handleRemoteErrorModal("handleremoteerror", () => {
            dispatch(modalClose("handleremoteerror"));
            dispatch(remoteClearError(scope));
        }, error)))
});

const mapStateToProps = (s: ReduxState): StateProps => ({
    remoteErrors: s.prop("remoteErrors"),
    isAuthenticated: !isRemoteLoading(s, RemoteScope.INIT) && !s.prop("remoteErrors").get(RemoteScope.INIT) && !!s.prop("remote").prop(RemoteScope.INIT)?.user,
    isLoading: isRemoteLoading(s, RemoteScope.INIT)
});

/**
 *
 */
export type Props = OwnProps & StateProps & DispatchProps;

/**
 * AppComp implements Claude's root component.
 */
class AppComp
    extends React.Component<Props, State> {

    public constructor(props: Props) {
        super(props);

        this.state = {init: true};

        this.checkRemoteError = this.checkRemoteError.bind(this);
        this.updateInit = this.updateInit.bind(this);
    }

    public componentDidMount() {
        this.checkRemoteError();
        this.updateInit();
    }

    public componentDidUpdate(prevProps: Props) {
        this.checkRemoteError();
        this.updateInit(prevProps);
    }

    /**
     *
     */
    public render() {
        return (
            <Component
                {...this.props}
                isLoading={this.state.init || this.props.isLoading}
            />
        );
    }

    private updateInit = (prevProps?: Props) => {
        if(this.state.init){
            if (prevProps) {
                if ((this.props.isLoading !== prevProps.isLoading) || this.props.isAuthenticated){
                    this.setState({
                        init: false,
                    });
                }
            } else {
                if (this.props.isLoading || this.props.isAuthenticated){
                    this.setState({
                        init: false,
                    });
                }
            }
        }
    }

    private checkRemoteError = () => {
        const errorEntry = this.props.remoteErrors.findEntry((val) => !!val);
        if (!!errorEntry) {
            const [scope, reason] = errorEntry;
            if(scope !== RemoteScope.INIT) {
                this.props.handleRemoteError(scope, reason);
            };
        }
    };
}

/**
 *
 */
const AppWrapper: React.FunctionComponent<OwnProps> = props => (<AppComp {...props as Props} />);

/**
 *
 */
export const App = connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppWrapper);

export const testUtil = {
    AppComp: AppComp,
    handleRemoteErrorModal: handleRemoteErrorModal,
    mapDispatchToProps: mapDispatchToProps,
    mapStateToProps: mapStateToProps,
};
