/* eslint-disable react/display-name */
/* eslint-disable max-len */
import * as React from "react";
import { connect } from "react-redux";
import { FlexWrapperFocus } from "../../../../components/core/03-base/FlexWrapper";
import { Button } from "../../../../components/core/05-atoms/Button";
import { Navbar } from "../../../../components/core/07-organisms/Navbar";
import { NavbarIcon } from "../../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStack, NavbarStackContent, NavbarStackLeft, NavbarStackRight } from "../../../../components/core/07-organisms/Navbar/Stack";
import { NavbarTitle } from "../../../../components/core/07-organisms/Navbar/Title";
import { DispatchFunc } from "../../../../store/ActionTypes";
import { ReduxState } from "../../../../store/ReduxState";
import { ThemePalette, ThemeShadowSizes } from "../../../../theme/_Types";
import { flexSetFocus, modalClose } from "../../../../utils/redux/ActionTypes";
import * as I18n from "../../../../utils/translations/I18n";
import { LogoutBtn } from "../../05-atoms/keycloaklogoutbtn";

interface OwnProps { }

interface DispatchProps {
    setFocus: () => void;
    closeModal: (id: string) => void;
}

interface StateProps {
}

const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    setFocus: () => dispatch(flexSetFocus(FlexWrapperFocus.SIDEBAR)),
    closeModal: (id: string) => {
        dispatch(modalClose(id));
    },
});

const mapStateToProps = (_state: ReduxState): StateProps => ({
});

type Props = OwnProps & StateProps & DispatchProps;

class HomeNavBarComp extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
        this.setFocus = this.setFocus.bind(this);
    }

    public render() {
        return (
            <Navbar
                theme={{
                    palette: ThemePalette.CONTRAST_PRIMARY,
                    shadow: ThemeShadowSizes.SMALL,
                }}
                className={"scl-o-navbar__toggle-sidebar"}
            >
                <NavbarStack>
                    <NavbarStackLeft>
                        {
                            <NavbarIcon
                            >
                                <Button icon={"bars"}
                                    className="scl-o-navbar__icon"
                                    link={{ onClick: this.setFocus() }}
                                />
                            </NavbarIcon>
                        }
                    </NavbarStackLeft>
                    <NavbarStackContent>
                        <NavbarTitle>{I18n.getText("app.app-title", "Downtime Registration Application")}</NavbarTitle>
                    </NavbarStackContent>
                    <NavbarStackRight>
                        <NavbarIcon>
                            <LogoutBtn />
                        </NavbarIcon>
                    </NavbarStackRight>
                </NavbarStack>
            </Navbar>
        );
    }

    private setFocus() {
        return () => this.props.setFocus();
    }
}

export const HomeNavBarWrapper: React.FunctionComponent<OwnProps> = props => (<HomeNavBarComp {...props as Props} />);

/**
 *
 */
export const HomeNavBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HomeNavBarWrapper);

export const testUtil = {
    mapDispatchToProps: mapDispatchToProps,
    mapStateToProps: mapStateToProps,
};
