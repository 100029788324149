/* eslint-disable max-len */
import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";
import { getCurrentEnv } from "./WhichEnv";


/**
 * @param config make sure you provide, applicationId, clientToken and service
 * @returns script version
 */
export const initLogging = (config: RumInitConfiguration) => {
    try {
        if (getCurrentEnv() !== "prod" && getCurrentEnv() !== "local") {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-var-requires
            const pkg = require("../../package.json");
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            console.log(`Claude logging is enabled! (${pkg.version as string | undefined ?? "Unknown version"} - 
                ${getCurrentEnv()})`);

            datadogRum.init({
                site: "datadoghq.eu",
                ...config,
                // applicationId: "x",
                // clientToken: "x",
                // service: "x",
                env: getCurrentEnv(),
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
                version: pkg.version,
            });

            datadogRum.startSessionReplayRecording();
        } else {
            console.warn("Claude logging is not enabled! We don't support this stage: ", getCurrentEnv());
        }
    } catch (e) {
        console.error("Claude logging is not enabled! Something went wrong: ", e);
    }
};
