import Axios, { AxiosInstance, CancelTokenSource } from "axios";
import { Map, OrderedSet } from "immutable";
import { Action } from "redux";
import { Opt, Optional } from "../utils/Optional";
import { MaryState } from "../utils/redux/MaryProvider";
import { State, StateInit } from "../utils/redux/State";
import { ActionTypeKeys, Config, ReducerFunc } from "./ActionTypes";
import { Pages } from "./AppDisplays";
import { UserInfo } from "./AppTypes";
import { InitApp } from "./Init/Types";
import { RemoteErrorType, RemoteScope } from "./RemoteTypes";

export const isRemoteLoading =
    (state: ReduxState, scope: RemoteScope): boolean =>
        state.mapProp("remotes", r => r.has(scope));

export const isRemoteLoadingAny =
    (state: ReduxState): boolean =>
        state.mapProp("remotes", r => !r.isEmpty());

export interface RemoteErrors {
    message: string;
    type: RemoteErrorType;
}

export type CurrentPage = Readonly<{
    pages: OrderedSet<Pages>;
}>;


interface TState {
    conf: Config;
    client: AxiosInstance;
    remotes: Map<RemoteScope, Opt<CancelTokenSource>>;
    remote: State<RemoteState>;
    remoteErrors: Map<RemoteScope, RemoteErrors>;
    mary?: MaryState;
    user: Optional<UserInfo>;
    sidebarCollapsed: boolean;
}

/**
 *
 */
export interface RemoteState {
    [RemoteScope.INIT]?: InitApp;
}

export type ReduxState = State<TState>;

export type ReducerFn<A extends Action<ActionTypeKeys>> =
    ReducerFunc<ReduxState, A>;

export type Filters = Readonly<{
}>;

export type FilterStates = {
    [K in keyof Filters]: State<Filters[K]>;
};

export const clearState = (s: ReduxState) => s.clear({mary: true, conf: true});

export const ReduxStateInit: (config: Config) => StateInit<TState> =
    config => (): TState => {
        const isCollapsed = localStorage.getItem?.("isCollapsed");
        return ({
            conf: config,
            client: Axios.create({
                baseURL: config.apiEndpoint,
            }),
            remotes: Map(),
            remote: State.create<RemoteState>(() => ({})),
            remoteErrors: Map(),
            mary: undefined,
            user: Optional.empty(),
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            sidebarCollapsed: JSON.parse(isCollapsed ?? "false") ?? false,
        });
    };

export const initTestState: ReduxState = State.create(ReduxStateInit({apiEndpoint: ""}));
