/* eslint-disable no-underscore-dangle */
/* eslint-disable react/display-name */
/* eslint-disable max-len */
import * as React from "react";
import { Map } from "immutable";
import { connect } from "react-redux";
import { BaseConsumer } from "../../../components/core/03-base/Base";
import { FlexColumn } from "../../../components/core/03-base/Flex/Column";
import { FlexRow } from "../../../components/core/03-base/Flex/Row";
import { FlexSidebar } from "../../../components/core/03-base/Flex/Sidebar";
import { FlexWrapperFocus } from "../../../components/core/03-base/FlexWrapper";
import { Logo } from "../../../components/core/03-base/Logo";
import { Button } from "../../../components/core/05-atoms/Button";
import { IconNames } from "../../../components/core/05-atoms/Icon";
import { UserInfo as UserInfoComp } from "../../../components/core/06-molecules/UserInfo";
import { NavbarIcon } from "../../../components/core/07-organisms/Navbar/Icon";
import { NavbarStackContent, NavbarStackRight } from "../../../components/core/07-organisms/Navbar/Stack";
import { DispatchFunc } from "../../../store/ActionTypes";
import { toggleSidebar } from "../../../store/AppActions";
import { isRemoteLoading, ReduxState, RemoteErrors } from "../../../store/ReduxState";
import { ThemePalette, ThemeShadowSizes, ThemeVariants } from "../../../theme/_Types";
import { BreakpointConsumer } from "../../../utils/Breakpoints";
import { flexSetFocus } from "../../../utils/redux/ActionTypes";
import { SwipeBlock } from "../../../utils/swipe";
import * as I18n from "../../../utils/translations/I18n";
import backgroundImage from "../../assets/images/sidebar-bg.png";
import { ReleaseVersion } from "../../../components/core/05-atoms/ReleaseVersion";
import { LogoutBtn } from "../05-atoms/keycloaklogoutbtn";
import logoImageVWPFS from "../../assets/images/logos/vwpfs/logo-contrast.svg";
import { UserInfo } from "../../../store/AppTypes";
import { KeycloakLoggedIn, KeycloakNotLoggedIn } from "../05-atoms/keycloakLoggedIn";
import { Optional } from "../../../utils/Optional";
import { PageDisplay, Pages } from "../../../store/AppDisplays";
import { DashboardMenu } from "../../../components/core/09-views/00-blocks/DashboardMenu";
import { PageType } from "../../../components/core/09-views/01-sections/Page";
import { InitApp } from "../../../store/Init/Types";
import { RemoteScope } from "../../../store/RemoteTypes";
import { LoadingIndications, LoadingIndicator } from "../../../components/core/05-atoms/LoadingIndicator";

/**
 *
 */
interface StateProps {
    sidebarCollapsed: boolean;
    user: Optional<UserInfo>;
    init?: InitApp;
    isLoading: boolean;
    isAuthenticated: boolean;
    remoteErrors: Map<RemoteScope, RemoteErrors>;
}

/**
 *
 */
interface DispatchProps {
    toggleSidebar: (force?: boolean) => void;
    setFocus: () => void;
}

type Props = StateProps & DispatchProps;


/**
 *
 * @param s
 */
const mapStateToProps = (s: ReduxState): StateProps => ({
    sidebarCollapsed: s.prop("sidebarCollapsed"),
    user: s.prop("user"),
    init: s.prop("remote").prop(RemoteScope.INIT),
    remoteErrors: s.prop("remoteErrors"),
    isAuthenticated: !isRemoteLoading(s, RemoteScope.INIT) && !s.prop("remoteErrors").get(RemoteScope.INIT) && !!s.prop("remote").prop(RemoteScope.INIT)?.user,
    isLoading: isRemoteLoading(s, RemoteScope.INIT),
});


/**
 *
 */
export const mapDispatchToProps = (dispatch: DispatchFunc): DispatchProps => ({
    toggleSidebar: (force?: boolean) => dispatch(toggleSidebar(force)),
    setFocus: () => dispatch(flexSetFocus(FlexWrapperFocus.SIDEBAR_INACTIVE)),
});

/**
 *
 * @param _props
 */
class SideBarComp extends React.Component<Props, {}> {

    public constructor(props: Props) {
        super(props);
        this.updateCollapseState = this.updateCollapseState.bind(this);
        this.setFocus = this.setFocus.bind(this);
        this.updateCollapseStateWithIcon = this.updateCollapseStateWithIcon.bind(this);
    }

    public render() {
        return (
            <BaseConsumer>{({ getTheme }) =>
                (
                    <SwipeBlock detectSwipeDir={(value: string) => this.updateCollapseState(value === "Left")}>
                        <FlexSidebar isCollapsed={this.props.sidebarCollapsed}>
                            <BreakpointConsumer>
                                {(value) => (
                                    <>
                                        <FlexRow theme={{ palette: ThemePalette.BRAND_PRIMARY }}>
                                            <FlexColumn hasScrollable className="v-sidebar"
                                                style={{ backgroundImage: `url(${backgroundImage})` }}
                                            >
                                                <FlexRow
                                                    isFixed
                                                    className="v-sidebar__userinfo"
                                                >
                                                    <NavbarStackContent>
                                                        {
                                                            (!this.props.sidebarCollapsed || !value.breakpointAtSM) ? (
                                                                <>
                                                                    <KeycloakLoggedIn>
                                                                        {this.props.isAuthenticated ? (
                                                                            <UserInfoComp
                                                                                name={this.props.user.get().userName || (I18n.getText("app.userNotAuthenticated", "Can't authenticate user!") ?? "")}
                                                                                role={"VWPFS DRA"}
                                                                                // eslint-disable-next-line react/no-children-prop
                                                                                children={this.props.isLoading && <LoadingIndicator type={LoadingIndications.DEFAULT}
                                                                                    theme={{ palette: ThemePalette.CONTRAST_ACCENT }} />}
                                                                            />) : (
                                                                            <UserInfoComp
                                                                                name={I18n.getText("app.userNotAuthenticated", "Can't authenticate user!") ?? ""}
                                                                                // eslint-disable-next-line react/no-children-prop
                                                                                children={this.props.isLoading && <LoadingIndicator type={LoadingIndications.DEFAULT}
                                                                                    theme={{ palette: ThemePalette.CONTRAST_ACCENT }} />}
                                                                            />)
                                                                        }
                                                                    </KeycloakLoggedIn>
                                                                    <KeycloakNotLoggedIn>
                                                                        <UserInfoComp
                                                                            name={"Not loggedin"}
                                                                        />
                                                                    </KeycloakNotLoggedIn>
                                                                </>)
                                                                : <></>}
                                                    </NavbarStackContent>
                                                    <NavbarStackRight isCollapsed={this.props.sidebarCollapsed && value.breakpointAtSM}>
                                                        {
                                                            <>
                                                                <>
                                                                    {(this.props.sidebarCollapsed && value.breakpointAtSM) &&
                                                                        <NavbarIcon>
                                                                            <Button
                                                                                className={"scl-o-navbar__icon"}
                                                                                link={{ href: "/" }}
                                                                                icon="home" />
                                                                        </NavbarIcon>
                                                                    }
                                                                    <>
                                                                        <KeycloakLoggedIn>
                                                                            {value.breakpointAtSM &&
                                                                                <NavbarIcon>
                                                                                    <LogoutBtn />
                                                                                </NavbarIcon>
                                                                            }
                                                                        </KeycloakLoggedIn>
                                                                    </>
                                                                </>
                                                                {!value.breakpointAtSM &&
                                                                <NavbarIcon>
                                                                    <Button
                                                                        className={"scl-o-navbar__icon"}
                                                                        link={{
                                                                            onClick: this.setFocus(),
                                                                        }}
                                                                        icon={IconNames.TIMES}
                                                                    />
                                                                </NavbarIcon>
                                                                }
                                                            </>
                                                        }
                                                    </NavbarStackRight>
                                                </FlexRow>
                                                <FlexRow isScrollable className="v-sidebar__menu">
                                                    <FlexColumn className="v-sidebar__menu-content">
                                                        {(this.props.isAuthenticated && (!this.props.sidebarCollapsed || !value.breakpointAtSM)) &&
                                                            <DashboardMenu
                                                                tabs={Object.keys(PageDisplay).map((page): PageType => ({
                                                                    ...PageDisplay[page as Pages],
                                                                    title: I18n.getText(PageDisplay[page as Pages].title) ?? "n.a.",
                                                                }))}
                                                            />
                                                        }
                                                        <FlexRow className={(this.props.sidebarCollapsed && value.breakpointAtSM) ? "v-sidebar__version-collapsed" : "v-sidebar__version"} isCollapsed={this.props.sidebarCollapsed || !value.breakpointAtSM}>
                                                            {(!this.props.sidebarCollapsed || !value.breakpointAtSM) &&
                                                            <>
                                                                <div className="v-sidebar__title">
                                                                    {I18n.getText("app.app-title", "Downtime Registration Application")}
                                                                </div>
                                                                <Logo img={logoImageVWPFS} theme={getTheme()} variant={ThemeVariants.CONTRAST} className="v-sidebar__logo--vwpfs" />
                                                            </>
                                                            }
                                                            <ReleaseVersion
                                                                isCollapsed={this.props.sidebarCollapsed && value.breakpointAtSM}
                                                            />
                                                        </FlexRow>
                                                    </FlexColumn>
                                                </FlexRow>
                                            </FlexColumn>
                                        </FlexRow>
                                        {(value.breakpointAtSM) && (
                                            <Button
                                                link={{ onClick: this.updateCollapseStateWithIcon(!this.props.sidebarCollapsed) }}
                                                theme={{
                                                    palette: ThemePalette.BRAND_ACCENT,
                                                    shadow: ThemeShadowSizes.TINY,
                                                }}
                                                className="m-info-card__icon"
                                                icon={this.props.sidebarCollapsed ? "chevron-right" : "chevron-left"}
                                                style={{ marginRight: "-35px", top: "50%" }}
                                            />
                                        )}
                                    </>
                                )}
                            </BreakpointConsumer>
                        </FlexSidebar>
                    </SwipeBlock>
                )}
            </BaseConsumer>
        );
    }

    private updateCollapseStateWithIcon(collapse: boolean) {
        return () => this.updateCollapseState(collapse);
    }

    private updateCollapseState(collapse: boolean) {
        this.props.toggleSidebar(collapse);
    }

    private setFocus() {
        return () => this.props.setFocus();
    }
}

const SideBarWrapper: React.FunctionComponent = props => (<SideBarComp {...props as Props} />);

/**
 *
 */
export const SideBar = connect(
    mapStateToProps,
    mapDispatchToProps,
)(SideBarWrapper);
