import { Email, Emails } from "../Emails/Types";
import { Pagination } from "../RemoteTypes";
import { Supplier } from "../Suppliers/Types";
import { VehicleContext } from "../Vehicles/Types";

export enum DRAStatus {
    NEW = "new",
    PENDING_REGISTRATION = "pending_registration",
    PENDING_CORRECTION = "pending_correction",
    RECORDED = "recorded",
}

export const DRAStatusDisplay: Readonly<Record<DRAStatus, string>> = {
    [DRAStatus.NEW]: "Nieuw",
    [DRAStatus.PENDING_REGISTRATION]: "Wacht op registratie",
    [DRAStatus.PENDING_CORRECTION]: "Wacht op correctie",
    [DRAStatus.RECORDED]: "Afgerond",
};

export enum Exceeded {
    NONE = "none",
    CUSTOMER = "customer",
    DEALER = "dealer",
    DEALER_AND_CUSTOMER = "dealer_and_customer",
}


export type ExceededDowntime = Partial<{
    downtimeDateStarted: string;
    downtimeTimeStarted: string;
    downtimeDateEnded: string;
    downtimeTimeEnded: string;
}>;

export type ExceededReason = ExceededDowntime & {
    reason?: string;
    downtime?: string;
};

export type DowntimeRegistrationBase = ExceededDowntime & Partial<{
    id: string;
    amountExVat: number;
    description: string;
    downtime: string;
    maintenanceDateStarted: string;
    exceeded: Exceeded;
    exceededByDealer: ExceededReason;
    exceededByCustomer: ExceededReason;
    // disclaimer: boolean, (not needing this)
    updatable: boolean;
    recorded: boolean;
    vehicle: VehicleContext;
    supplier: Supplier;
    status: DRAStatus;
}>;

export type DowntimeRegistration =  DowntimeRegistrationBase & Partial<{
    emailSent: boolean;
    lastEmailSent: Email;
    emails: Emails;
}>;

export interface DowntimeRegistrations extends Pagination {
    data: DowntimeRegistration[];
}

export type DowntimeRegistrationFilters = Partial<{
    // dateStarted?: string;
    amountExVat?: number;
    recorded?: boolean;
    emailAddress?: string;
    lastEmailSendDate?: string;
    supplier?: string;
    licensePlate?: string;
    fromDate?: string;
    toDate?: string;
    status?: DRAStatus;
}>;

// CUSTOMER
export enum CustomerCodes {
    "DEFENSIE" = 1,
    "POLITIE" = 2,
}


export const CustomerCodeDisplay: Readonly<Record<CustomerCodes, string>> = {
    [CustomerCodes.DEFENSIE]: "Defensie",
    [CustomerCodes.POLITIE]: "Politie",
};

export interface Customer {
    code: CustomerCodes;
    name: string;
}

