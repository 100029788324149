/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable max-len */
import "core-js";

import { I18n } from "@aws-amplify/core";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";

import {
    BrowserRouter as Router,
} from "react-router-dom";

import { unregister } from "../src/registerServiceWorker";
import { App } from "./App";
import { SideBar } from "./App/components/09-views/Sidebar";
import { VerifyContextWrapper } from "./App/utils/VerifyContextWrapper";
import { BaseProvider } from "./components/core/03-base/Base";
import { FlexColumn } from "./components/core/03-base/Flex/Column";
import { FlexRow } from "./components/core/03-base/Flex/Row";
import { FlexWrapperFocus } from "./components/core/03-base/FlexWrapper";
import { createReduxStore } from "./store/ReduxStore";
import { ThemePalette, Themes } from "./theme/_Types";
import { ErrorBoundary } from "./utils/Boundary";
import { ensure } from "./utils/Optional";
import { MaryProvider } from "./utils/redux/MaryProvider";
import { I18nLanguage, I18nMarylocales } from "./utils/translations/I18n";
import { I18nAmplifyAuthTranslationsNL, I18nMaryTranslationsNL } from "./utils/translations/nl/I18n";
import { BreakpointConsumer } from "./utils/Breakpoints";
import { HomeNavBar } from "./App/components/09-views/00-blocks/NavBar";
import { KeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import { KeycloakAuthenticated } from "./App/components/05-atoms/keycloakAuthenticated";
import { PreLoader } from "./components/core/03-base/Preloader";
import AxiosProvider from "./App/utils/AxiosContext";
import { initLogging } from "./utils/Logging";
import { DefaultPrivacyLevel } from "@datadog/browser-rum";
import { I18nAppTranslationsNL } from "./App/utils/translations/nl/I18n";
import "./App/assets/stylesheets/themes/vwpfs/index.scss";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5";

const elm = document.getElementById("root") as HTMLElement;

/**
 *
 */
enum ConfigProps {
    API_ENDPOINT = "data-api-endpoint",
}

I18n.configure(I18nMarylocales);
I18n.putVocabularies({
    nl: {
        ...I18nAmplifyAuthTranslationsNL,
        ...I18nMaryTranslationsNL,
        ...I18nAppTranslationsNL,
    },
});

const store = createReduxStore({
    apiEndpoint: ensure(elm.getAttribute(ConfigProps.API_ENDPOINT) ?? undefined),
});

const queryClient = new QueryClient();

initLogging({
    applicationId: "291ef5ae-a881-4be6-a6ad-f82097ca53a4",
    clientToken: "pub8eb6a4994dd393afa4b94bece6467ec3",
    service: "dra-portal",
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: DefaultPrivacyLevel.ALLOW,
});

/**
 *
 */
const Root: React.FunctionComponent<{}> = () => (
    <KeycloakProvider
        keycloak={keycloak}
        initConfig={{ onLoad: "login-required", checkLoginIframe: false }}
    >
        <BaseProvider
            theme={process.env.REACT_APP_THEME as Themes}
            language={I18nLanguage.DUTCH}
        >
            <ErrorBoundary>
                <Provider store={store}>
                    <Router>
                        <QueryParamProvider adapter={ReactRouter5Adapter}>
                            <KeycloakAuthenticated onAuthenticating={<PreLoader force />}>
                                <AxiosProvider>
                                    <QueryClientProvider client={queryClient}>
                                        <MaryProvider
                                            flexDefaultFocus={FlexWrapperFocus.SIDEBAR_INACTIVE}
                                        >
                                            <SideBar />
                                            <FlexColumn isPage >
                                                <BreakpointConsumer>
                                                    {(value) => {
                                                        if (!value.breakpointAtSM) {
                                                            return (
                                                                <FlexRow isFixed>
                                                                    <HomeNavBar />
                                                                </FlexRow>
                                                            );
                                                        } else {
                                                            return null;
                                                        }
                                                    }}
                                                </BreakpointConsumer>
                                                <FlexRow
                                                    theme={{ palette: ThemePalette.CONTRAST_SECONDARY }}
                                                >
                                                    <FlexColumn hasScrollable>
                                                        <VerifyContextWrapper>
                                                            <App />
                                                        </VerifyContextWrapper>
                                                    </FlexColumn>
                                                </FlexRow>
                                            </FlexColumn>
                                            <PreLoader />
                                        </MaryProvider>
                                    </QueryClientProvider>
                                </AxiosProvider>
                            </KeycloakAuthenticated>
                        </QueryParamProvider>
                    </Router>
                </Provider>
            </ErrorBoundary>
        </BaseProvider>
    </KeycloakProvider>
);

ReactDOM.render(<Root />, elm);
unregister();
