/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { AxiosResponse } from "axios";
import { InitApp } from "./Types";

/**
 *
 * @param resp
 */
export const mapInitRequest =
 (resp: AxiosResponse): InitApp => resp?.data;
