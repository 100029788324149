/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
import { lazy } from "react";
import { OrderedSet } from "immutable";
import { Exceeded } from "./DowntimeRegistrations/Types";

const DowntimRegistrations = lazy(() => import("../App/components/09-views/02-pages/DowntimeRegistrations"));
const DowntimRegistrationsDetails = lazy(() => import("../App/components/09-views/02-pages/DowntimeRegistrationsDetails"));
const Suppliers = lazy(() => import("../App/components/09-views/02-pages/Suppliers"));
const Vehicles = lazy(() => import("../App/components/09-views/02-pages/Vehicles"));

/**
 *
 */
export type DisplayOf<T extends string | number> = Readonly<Record<T, string>>;

/**
 *
 */
export const ExceededDisplay: Readonly<Record<Exceeded, string>> = {
    [Exceeded.NONE]: "Geen verwijt",
    [Exceeded.CUSTOMER]: "Klant",
    [Exceeded.DEALER]: "Derden",
    [Exceeded.DEALER_AND_CUSTOMER]: "Derden en Klant",
};

/**
 *
 */
export enum Pages {
    DOWNTIME_REGISTRATIONS = "DOWNTIME_REGISTRATIONS",
    VEHICLES = "VEHICLES",
    SUPPLIERS = "SUPPLIERS",
}

/**
 *
 */
export enum SubPages {
    DETAIL_DOWNTIME_REGISTRATIONS = "DETAIL_DOWNTIME_REGISTRATIONS"
}

/**
 *
 */
export enum PageTabs {
}

export interface Tab {
    title: string;
    description: string;
    roles: string[];
    component?: React.FunctionComponent | React.ComponentState;
}

export interface Page {
    title: string;
    description: string;
    path: string;
    roles: string[];
    component?: React.FunctionComponent | React.ComponentState;
    beta?: boolean;
    pagination?: boolean;
    parent?: Page;
    asMenu?: boolean;
    subpages: OrderedSet<SubPages>;
    tabs?: OrderedSet<PageTabs>;
}

/**
 *
 */
export const PageDisplay: Readonly<Record<Pages, Page>> = {
    [Pages.DOWNTIME_REGISTRATIONS]: {
        title: "app.downtime_registrations",
        description: "",
        component: DowntimRegistrations,
        path: "/downtime-registrations",
        roles: [],
        subpages: OrderedSet([SubPages.DETAIL_DOWNTIME_REGISTRATIONS]),
        tabs: OrderedSet([]),
    },
    [Pages.VEHICLES]: {
        title: "app.vehicles",
        description: "",
        component: Vehicles,
        path: "/dra-vehicles",
        roles: [],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
    [Pages.SUPPLIERS]: {
        title: "app.suppliers",
        description: "",
        component: Suppliers,
        path: "/suppliers",
        roles: [],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
};

/**
 *
 */
export const SubPageDisplay: Readonly<Record<SubPages, Page>> = {
    [SubPages.DETAIL_DOWNTIME_REGISTRATIONS]: {
        title: "app.downtime_registrations",
        description: "",
        component: DowntimRegistrationsDetails,
        path: "/downtime-registrations/:id",
        roles: [],
        subpages: OrderedSet([]),
        tabs: OrderedSet([]),
    },
};
