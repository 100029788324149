/* eslint-disable */
import { mapInitRequest } from "./Init/RemoteConfig";
import { remoteClearError, remoteClearResponse } from "./RemoteActions";
import {
    RemoteConfig,
    RemoteScope,
} from "./RemoteTypes";

/**
 *
 */
export const RemoteConfigs: { [K in RemoteScope]: RemoteConfig<K>; } = {
    [RemoteScope.INIT]: {
        scope: RemoteScope.INIT,
        onInit: (d, _s, _ctx) => {
            d(remoteClearResponse(RemoteScope.INIT));
            d(remoteClearError(RemoteScope.INIT));
        },
        pathMapper: () => "/base",
        resMapper: mapInitRequest,
        onSuccess: (d, s, _ctx) => {
            console.log("remote success: ",s.prop("remote")?.prop(RemoteScope.INIT));
        },
    },
};
