
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import React, { Suspense } from "react";
// import { useQuery, useQueryClient } from "react-query";
import { Redirect, Route, Switch } from "react-router-dom";
import { Props } from "../App";
import { Loading } from "../components/core/09-views/02-pages/Loading";
import { NoMatchingRoute } from "../components/core/09-views/02-pages/NoMatchingRoute";
import { PageDisplay, Pages, SubPageDisplay, SubPages } from "../store/AppDisplays";
import { NoRole } from "./components/09-views/02-pages/NoRole";


export const Component: React.FC<Props> = (props) => {
    const renderDashboardOrDefault = (page: Pages) => {
        if (page === Pages.DOWNTIME_REGISTRATIONS) {
            return [
                <Route exact path="/" key="redirect-root">
                    <Redirect to={PageDisplay[page].path} />
                </Route>,
                <Route
                    key={"route-" + PageDisplay[page].path}
                    path={PageDisplay[page].path}
                    component={PageDisplay[page].component}
                    exact
                />,
            ];
        } else {
            return (
                <Route
                    key={"route-" + PageDisplay[page].path}
                    path={PageDisplay[page].path}
                    component={PageDisplay[page].component}
                    exact
                />
            );
        }
    };

    const renderSubPage = (page: SubPages) => (
        <Route
            key={"route-" + SubPageDisplay[page].path}
            path={SubPageDisplay[page].path}
            component={SubPageDisplay[page].component}
            exact
        />
    );

    return (
        <Suspense fallback={<Loading />}>
            <Switch>
                {props.isAuthenticated ?
                    ((
                        [
                            Object.keys(PageDisplay).map((page) => ([
                                renderDashboardOrDefault(page as Pages),
                            ]
                            )),
                            Object.keys(SubPageDisplay).map((page, _index) => ([
                                renderSubPage(page as SubPages),
                            ]
                            )),
                            <Route
                                key={"fallback-no-route"}
                                render={() => (
                                    <NoMatchingRoute
                                    />
                                )}
                            />,
                        ]
                    )): (
                        <Route key={"fallback-no-role"} path="*" exact component={props.isLoading ? Loading : NoRole} />
                    )}
            </Switch>
        </Suspense>
    );
};

