import * as React from "react";
import { Menu, MenuItem, MenuOrientation } from "../../07-organisms/Menu";
import { PageType } from "../01-sections/Page";

// StateProps are props derived from Redux state through MapStateToProps.
interface StateProps {
}

/**
 *
 */
interface OwnProps {
    tabs: PageType[];
}

/**
 *
 */
interface DispatchProps {
}

/**
 *
 */
type Props = OwnProps & StateProps & DispatchProps;

/**
 *
 */
export const DashboardMenu = (props: Props) => (
    <Menu
        orientation={MenuOrientation.VERTICAL}
        items={getItems(props)}
    />
);

/**
 *
 * @param props
 */
const getItems = (props: Props): MenuItem[] =>
    props.tabs
        .map((page: PageType): MenuItem => ({
            children: page.title,
            navLink: page.path,
            beta: page.beta,
        }));
