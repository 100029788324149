/* eslint-disable */
import Keycloak from "keycloak-js";

// Setup Keycloak instance as needed
// Pass initialization options as required or leave blank to load from 'keycloak.json'
export const keycloak = new (Keycloak as any)({
    realm: process.env.REACT_APP_IRIS_REALM,
    url: process.env.REACT_APP_IRIS_URL,
    clientId: process.env.REACT_APP_IRIS_CLIENTID,
});

export default keycloak;

