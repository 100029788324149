/* eslint-disable max-len */
export const I18nAppTranslationsNL = {
    "app.userNotAuthenticated": "Gebruiker is niet geauthenticeerd",
    "app.app-title": "Downtime Registratie Applicatie",
    "app.general": "Algemeen",
    "app.yes": "Ja",
    "app.no": "Nee",
    "app.pdf": "PDF",
    "app.csv": "CSV",
    "app.date-of-update": "Update datum",
    "app.make-a-choice": "Maak een keuze",
    "app.validation.has-value-message": "{{label}} ingevuld.",
    "app.06-molecules.downloadpdfbutton.no-download": "Geen download beschikbaar",
    "app.go-to-link": "Ga naar link",
    "app.home.title": "Welkom,",
    "app.home.description": "U kunt alles doen wat te maken heeft met het beheer van de API's",
    "app.remote-error": "Claude kan niet aan uw aanvraag voldoen.",
    "app.fatal-remote-error": "Zelfs Claude weet niet wat er mis is!",
    "app.remote-init-error": "Geen API beschikbaar.",
    "app.no-id-message-error": "Geen id beschikbaar.",
    "app.remote-init-message":
        "Claude kan geen verbinding met de API maken.",
    "app.notification": "Notificatie",
    "app.warning": "Waarschuwing",
    "app.store.getremoteerrormessage.status": "Status:",
    "app.store.getremoteerrormessage.error": "Foutmelding:",
    "app.not-authorized.title": "Niet geautoriseerd.",
    "app.not-authorized.message":
        "U bent niet geautoriseerd, hierdoor kunnen wij geen verbinding met de API maken, ververs de pagina!",
    "app.disclaimer-title": "Juridische mededeling",
    "app.disclaimer-description": `Welkom bij de wagenpark beheer omgeving van,

#### Volkswagen Pon Financial Services
\n&nbsp;
\n
**Informatie over de volledigheid en aansprakelijkheidsuitsluiting voor gegevens en cijfers**

De gegevens en cijfers die wij, Volkswagen Pon Financial Services en/of dochters, Saturnus 1, 3824 ME Amersfoort, compileren en beschikbaar stellen, vertegenwoordigen een extra service voor u. De door ons verzamelde en beschikbaar gestelde gegevens en cijfers zijn met de grootst mogelijke zorgvuldigheid en naar beste weten opgesteld. Volkswagen Pon Financial Services en/of dochters geeft geen garantie op de volledigheid en juistheid van deze gegevens en cijfers met betrekking tot wettelijke en/of fiscale vereisten. Aansprakelijkheid van Volkswagen Pon Financial Services en/of dochters voor de volledigheid en juistheid van de gegevens en cijfers is daarom uitgesloten.

Heeft u in het kader van onze rapportage vragen teneinde te kunnen voldoen aan wettelijke en/of fiscale vereisten, neem dan s.v.p. contact op met uw Account Manager.`,
    // new
    "app.select-role": "Selecteer rol",
    "app.select-a-role": "Selecteer een rol",
    "app.continue": "Ga verder",
    "app.cancel": "Annuleren",
    "app.role-selected": "Rol geselecteerd",
    "app.role-provided": "Rol opgegeven",
    "app.no-active-user": "Gebruiker is niet bekend",
    "app.no-active-user-description": "Deze gebruiker is niet bekend in de Downtime Registratie Applicatie. U kunt deze applicatie niet gebruiken.",
    "app.role": "Rol",
    "app.email": "E-mail",
    "app.suppliers": "Dealers",
    "app.supplier": "Dealer",
    "app.maintenanceDate": "Datum",
    "app.downtime_registrations": "Downtime registraties",
    "app.vehicles": "Voertuigen",
    "app.role-type": "Rol type",
    "app.update": "Wijzig",
    "app.Loading..": "Laden..",
    "app.Loading role..": "Rol laden..",
    "app.retrieving-role(s)..": "Rol(len) laden..",
    "app.disclaimer": "Disclaimer",
    "app.dashboard": "Onderhoud registraties",
    "app.instruction-video": "Instructievideo",
    "app.manage-roles": "Beheer rollen",
    "app.agree": "Accepteer",
    "app.decline": "Weiger",
    "app.close": "Sluiten",
    "app.assign-roles": "Rollen toewijzen",
    "app.assign-role": "Rol toewijzen",
    "app.reports": "Rapporten",
    "app.step": "Stap",
    "app.summary": "Samenvatting",
    "app.not-all-content-can-be-loaded": "Niet alle content kan geladen worden.",
    "app.update-role": "Wijzig rol",
    "app.add-role": "Rol toevoegen",
    "app.email-provided": "E-mail opgegeven",
    "app.clear": "Wissen",
    "app.search": "Zoeken",
    "app.actions": "Acties",
    "app.name": "Naam",
    "app.company": "Bedrijf",
    "app.label": "Label",
    "app.brand": "Merk",
    "app.assign": "Toewijzen",
    "app.claude-thinks-something-went-wrong": "Er is iets misgegaan!",
    "app.manual-reporting": "Handmatige rapportages",
    "app.organizational-units": "Organisatie eenheden",
    "app.add-report": "Rapport toevoegen",
    "app.add-units": "Eenheid toevoegen",
    "app.licensePlate": "Kenteken",
    "app.customerCode": "Klant",
    "app.date": "Datum",
    "app.phone": "Telefoonnummer",
    "app.location": "Plaats",
    "app.bcs-garage": "BCS-garage",
    "app.source": "Bron",
    "app.dateStarted": "Startdatum",
    "app.dateEnded": "Einddatum",
    "app.timeStarted": "Starttijd",
    "app.timeEnded": "Eindtijd",
    "app.reason": "Verwijtbare downtime door",
    "app.description": "Toelichting verwijt",
    "app.status": "Status",
    "app.emailSent": "E-mail verzonden",
    "app.downtime": "Downtime",
    "app.culpableDowntime": "Verwijtbare downtime",
    "app.lastEmailSentDate": "Laatst verzonden mail",
    "app.addressee": "Geadresseerde",
    "app.typeOfEmail": "Soort e-mail",
    "app.time": "Tijd",
    "app.amountExVat": "Vanaf bedrag",
    "app.amountExVatLabel": "Bedrag",
    "app.sent-email": "E-mail versturen",
};
